<template>
  <div class="page-main about-page">
    <div v-html="htmlData"></div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "@vue/runtime-core";
import { getSettingData } from "@/api/main.js";
import { message } from "ant-design-vue";

export default defineComponent({
  name: "About",
  setup() {
    const pageData = reactive({
      htmlData: null,
    });
    getSettingData({ setting_id: 2 })
      .then((res) => {
        if (res.code == 200) {
          pageData.htmlData = res.data.setting.setting_content;
        } else {
          message.error(res.msg);
        }
      })
      .catch((res) => {
        console.log(res);
        message.error("网络有误，请稍后再试");
      });

    return {
      ...toRefs(pageData),
    };
  },
});
</script>

<style lang="less" scoped>
.about-page {
  height: 100%;
  padding: 30px 30px 50px;
  background-color: #fff;
  img {
    max-width: 100%;
  }
}
</style>
